<template>
  <input
    :id="id"
    v-model="checked"
    :value="value"
    type="checkbox"
    class="toggle"
    :disabled="disabled || readonly"
    :class="toggleClasses"
    :name="name"
  >
</template>

<script lang="ts" setup generic="T extends boolean | any[]">
import { Ref, computed, inject, ref } from 'vue'

import { checkForUndefined } from '../../utils'

import type { ToggleColor, ToggleSize, ToggleVariant } from './types'

import * as classes from './classes'

const props = withDefaults(defineProps<{
  modelValue: T
  value?: T extends any[] ? T[number] : never
  color?: ToggleColor
  offColor?: ToggleColor // This prop is supported by 'solid' variant only
  size?: ToggleSize
  variant?: ToggleVariant
  disabled?: boolean
  readonly?: boolean
  name?: string
  id?: string
}>(), {
  name: '',
  id: '',
  color: 'default',
  offColor: 'default',
  size: 'md',
  variant: 'solid',
  disabled: false,
  readonly: false,
  value: undefined,
})

const emits = defineEmits<{
  'update:modelValue': [T]
}>()

const validationHandleChange = inject<Ref<((value: T) => void) | null>>('validationHandleChange', ref(null))

const checked = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:modelValue', value)

    if (typeof validationHandleChange.value === 'function') {
      validationHandleChange.value(value)
    }
  },
})

const toggleClasses = computed(() => {
  const color = checkForUndefined(props.color, classes.color)
  const offColor = checkForUndefined(props.offColor, classes.offColor)
  const size = checkForUndefined(props.size, classes.size)
  const variant = checkForUndefined(props.variant, classes.variant)

  return {
    [color]: !!props.color,
    [offColor]: !!props.offColor,
    [size]: !!props.size,
    [variant]: !!props.variant,
    readonly: props.readonly,
  }
})
</script>

<style scoped src="./toggle.styles.css"></style>
