<template>
  <Paper :shadow="shadow" :rounded="rounded" :variant="variant" :color="color">
    <Box flex direction="col" :py="verticalPadding" :class="cardClasses" :height="height" :justify="justify">
      <slot name="header">
        <CardHeader v-if="showHeader" :px="horizontalPadding">
          <Box flex row alignItems="center" justify="between" width="100%">
            <slot name="headerTitle">
              <CardTitle>
                {{ title }}
              </CardTitle>
            </slot>

            <Box v-if="$slots.headerActions" flex justify="end" space="2" wrap="wrap">
              <slot name="headerActions" />
            </Box>
          </Box>

          <ButtonIcon
            v-if="collapse"
            icon="keyboard_arrow_up"
            size="xs"
            class="card-collapse-arrow"
            :disabled="collapseDisabled"
            @click="onCollapseClick"
          />
        </CardHeader>
      </slot>

      <Transitions name="slide">
        <Box v-show="isOpened">
          <Divider v-if="showDivider" />

          <Box :px="horizontalPadding">
            <slot />
          </Box>

          <CardFooter v-if="$slots.footer" :px="horizontalPadding">
            <slot name="footer" />
          </CardFooter>
        </Box>
      </Transitions>
    </Box>
  </Paper>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { computed, ref, useSlots, watch } from 'vue'

import { useFormEvents } from '@lasso/shared/hooks'

import type { PaperColor, PaperRounded, PaperShadow, PaperVariant } from '../../components/Paper/types'

import * as paperClasses from '../Paper/classes'

import Box from '../Box/Box.vue'
import ButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import Divider from '../Divider/Divider.vue'
import Transitions from '../Transitions/Transitions.vue'
import Paper from '../Paper/Paper.vue'

import { BoxJustify } from '../Box/types'

import CardFooter from './CardFooter.vue'
import CardTitle from './CardTitle.vue'
import CardHeader from './CardHeader.vue'

import type { CardProps } from './types'

const props: CardProps = defineProps({
  title: {
    type: String,
    default: '',
  },
  variant: {
    type: String as PropType<PaperVariant>,
    default: 'contained',
    validator: (variant: PaperVariant) => typeof paperClasses.variant[variant] === 'string',
  },
  color: {
    type: String as PropType<PaperColor>,
    default: 'base',
    validator: (color: PaperColor) => !!paperClasses.color[color],
  },
  shadow: {
    type: String as PropType<PaperShadow>,
    default: 'lg',
    validator: (shadow: PaperShadow) => !!paperClasses.shadow[shadow],
  },
  rounded: {
    type: String as PropType<PaperRounded>,
    default: 'xl',
    validator: (shadow: PaperRounded) => !!paperClasses.rounded[shadow],
  },
  height: {
    type: String,
    default: undefined,
  },
  justify: {
    type: String as PropType<BoxJustify>,
    default: undefined,
  },
  square: {
    type: Boolean,
    default: false,
  },
  withDivider: {
    type: Boolean,
    default: false,
  },
  disablePX: {
    type: Boolean,
    default: false,
  },
  disablePY: {
    type: Boolean,
    default: false,
  },
  collapse: {
    type: Boolean,
    default: false,
  },
  collapseDisabled: {
    type: Boolean,
    default: false,
  },
  opened: {
    type: Boolean,
    default: false,
  },
})

const isOpened = ref(props.collapse ? props.opened : true)

const cardClasses = computed(() => {
  return {
    opened: isOpened.value,
  }
})

const slots = useSlots()

const showHeader = computed(() => slots.headerTitle || !!props.title || props.collapse || slots.headerActions)
const showDivider = computed(() => props.withDivider || (props.withDivider && props.collapse))
const horizontalPadding = computed(() => (props.disablePX ? '0' : '6'))
const verticalPadding = computed(() => (props.disablePY ? '0' : '6'))

const onCollapseClick = () => {
  if (props.collapseDisabled) {
    return
  }
  isOpened.value = !isOpened.value
}

watch(
  () => props.opened,
  () => {
    isOpened.value = props.opened
  },
)

const formEvents = useFormEvents()

// Catch the event thrown by form fields with errors on validation and open the section to show the error to the user
formEvents.fieldError.on(() => {
  if (!props.collapseDisabled) {
    isOpened.value = true
  }
})
</script>

<style scoped src="./card.styles.css" />

<style scoped>
.card-collapse-arrow {
  @apply transition-transform animate-none rotate-90;
}

.opened .card-collapse-arrow {
  @apply -rotate-180;
}
</style>
