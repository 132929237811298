<template>
  <Box class="breadcrumbs">
    <ul>
      <li
        v-for="(crumb, index) in crumbs"
        :key="crumb.path"
        :class="getStateClass(index)"
      >
        <RouterLink v-if="index + 1 < crumbs.length" :to="crumb.path">
          {{ crumb.label }}
        </RouterLink>
        <template v-else>
          {{ crumb.label }}
        </template>
      </li>
    </ul>
  </Box>
</template>

<script lang="ts" setup>
import type { RouterLink } from 'vue-router'

import Box from '../Box/Box.vue'

import { BreadcrumbsCrumb } from './types'

const props = defineProps<{
  crumbs: BreadcrumbsCrumb[]
}>()

const getStateClass = (index: number) => ({
  'breadcrumb-inactive': props.crumbs?.length - 1 !== index,
  'breadcrumb-active': props.crumbs?.length - 1 === index,
})
</script>

<style scoped>
.breadcrumbs {
  @apply py-0 text-12;
}
.breadcrumb-active {
  @apply !text-textSecondary;
}
.breadcrumb-inactive {
  @apply text-textSecondaryLight;
}
</style>
