<template>
  <Box class="select-panel-item">
    <Box
      :component="$slots.default ? 'button' : 'label'"
      :type="$slots.default ? 'button' : ''"
      class="select-panel-item-inner"
      @click="$slots.default ? toggleExpanded() : () => {}"
    >
      <Checkbox
        v-model="modelValueInternal"
        :value="value as any"
        :disabled="disabled"
        :indeterminate="indeterminate"
        @click.stop
      />
      <Box class="select-panel-item-content">
        <slot name="content">
          <slot name="chip">
            <template v-if="chips.length > 0">
              <Box
                v-for="_chip in chips"
                :key="_chip"
                class="flex-shrink-0"
                minWidth="10px"
                flex
              >
                <Chip variant="outlined" size="2xs" :label="_chip" />
              </Box>
            </template>
          </slot>
          <Tooltip class="flex min-w-0 gap-2" placement="top">
            <Typography
              ref="labelRef"
              variant="body2"
              display="block"
              class="select-panel-item-label"
              :class="{ singleLine }"
              wordBreak="anywhere"
            >
              <slot name="label">
                <Highlight :highlight="searchQuery" :label="label" />
              </slot>&nbsp;<Tooltip
                v-if="info && !singleLine"
                :content="info"
                placement="top"
                withIcon
                inline
                class="align-middle"
              />
            </Typography>
            <template v-if="overflown && singleLine" #content>
              {{ label }}
            </template>
          </Tooltip>
          <Tooltip
            v-if="info && singleLine"
            :content="info"
            placement="top"
            withIcon
            inline
            class="align-middle"
          />
        </slot>
      </Box>
      <Icon
        v-if="$slots.default"
        :icon="expandedInternal ? 'remove' : 'add'"
        variant="outlined"
      />
    </Box>
    <Transitions name="slide">
      <Box v-if="expandedInternal">
        <Box class="select-panel-item-children">
          <slot v-bind="{ nextLevel: level + 1 }" />
        </Box>
      </Box>
    </Transitions>
  </Box>
</template>

<script setup lang="ts" generic="T extends boolean | any[]">
import { useToggle, useVModel } from '@vueuse/core'

import { ComponentPublicInstance, computed, ref } from 'vue'

import { arrayify } from '@lasso/shared/utils'

import { useElementOverflown } from '@lasso/shared/hooks'

import Box from '../Box/Box.vue'
import Checkbox from '../Checkbox/Checkbox.vue'
import Chip from '../Chip/Chip.vue'
import Typography from '../Typography/Typography.vue'
import Highlight from '../Highlight/Highlight.vue'
import Icon from '../Icon/Icon.vue'
import Transitions from '../Transitions/Transitions.vue'
import Tooltip from '../Tooltip/Tooltip.vue'

const props = withDefaults(
  defineProps<{
    modelValue: T
    value?: T extends any[] ? T[number] : never
    indeterminate?: boolean
    searchQuery?: string
    chip?: string | number | Array<string | number>
    label?: string
    info?: string
    disabled?: boolean
    expanded?: boolean
    level?: number
    singleLine?: boolean
  }>(),
  {
    chip: '',
    searchQuery: '',
    label: '',
    info: '',
    disabled: false,
    indeterminate: false,
    expanded: false,
    level: 0,
    value: undefined,
  },
)

const emit = defineEmits<{
  'update:modelValue': [T]
  'update:expanded': [boolean]
}>()

const modelValueInternal = useVModel(props, 'modelValue', emit)
const expandedInternal = useVModel(props, 'expanded', emit, { passive: true })
const toggleExpanded = useToggle(expandedInternal)

const chips = computed(() =>
  arrayify(props.chip).filter(chip => chip !== ''),
)

const labelRef = ref<ComponentPublicInstance>()
const { overflown } = useElementOverflown(labelRef)
</script>

<style scoped>
.select-panel-item {
  @apply border-b border-base-500 last-of-type:border-b-0;
}

.select-panel-item-inner {
  @apply cursor-pointer flex items-center gap-2 grow flex-row px-4 py-1 min-h-[34px] w-full;
  padding-left: calc(16px * calc(v-bind(level) + 1));
}

.select-panel-item-content {
  @apply flex items-center gap-2 grow min-w-0;
}

.select-panel-item-children {
  @apply border-t border-base-500;
}

.select-panel-item-label {
  @apply basis-0 grow;
}

.select-panel-item-label.singleLine {
  @apply whitespace-nowrap truncate;
}
</style>
