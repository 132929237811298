<template>
  <li class="flex flex-col">
    <Typography
      v-if="disabled"
      v-bind="$attrs"
      class="list-item-button"
      :class="listItemClasses"
      disabled
    >
      <slot />
    </Typography>
    <LinkBase
      v-else
      v-bind="$attrs"
      type="button"
      class="list-item-button"
      :class="listItemClasses"
      @click="handleClick"
    >
      <slot />
    </LinkBase>
  </li>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'

import LinkBase from '../LinkBase/LinkBase.vue'
import Typography from '../Typography/Typography.vue'

import type { ListProps } from '../List/types'
import { checkForUndefined } from '../../utils'

import * as classes from './classes'

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits(['click'])

const listState = inject<ListProps>('ListState', {})

const listItemClasses = computed(() => {
  const textColor = checkForUndefined(listState.textColor, classes.textColor)
  const color = checkForUndefined(listState.color, classes.color)
  const activeColor = checkForUndefined(listState.activeColor, classes.activeColor)
  const shape = checkForUndefined(listState.shape, classes.shape)

  return {
    [color]: !!color, // inherited from list state
    [activeColor]: !!activeColor && props.active,
    [textColor]: !!textColor, // inherited from list state
    [shape]: !!listState.shape, // inherited from list state
    'py-1.5': !listState.dense, // inherited from list state
    'cursor-pointer': !props.disabled,
    'pointer-events-none': props.disabled,
  }
})

const handleClick = (e: Event) => {
  emits('click', e)
}
</script>

<style scoped>
.list-item-button {
  @apply flex select-none px-4 w-full outline-none text-left;
}
</style>
