<template>
  <BreadcrumbsBase :crumbs="crumbs" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import type { Router } from 'vue-router'

import { excludeElements, getBreadcrumbsData } from './utils'
import BreadcrumbsBase from './BreadcrumbsBase.vue'

const props = withDefaults(defineProps<{
  modelValue: Router
  skipCrumbsIndexes?: number[]
}>(), {
  skipCrumbsIndexes: () => [],
})

const crumbs = computed(() => {
  const breadCrumbsData = getBreadcrumbsData(props.modelValue)
  return excludeElements(breadCrumbsData, props.skipCrumbsIndexes)
})
</script>
