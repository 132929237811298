<template>
  <Dropdown :position="position" :hover="hover">
    <InputText
      v-bind="$attrs"
      :name="name"
      :modelValue="modelValue"
      :data-test-id="`autocomplete-input-${name}`"
      :variant="variant"
      :size="size"
      autocomplete="off"
      :hideLabel="hideLabel"
      :placeholder="placeholder"
      @input="handleInput"
    />
    <template #dropdown="scope">
      <Paper
        :id="`autocomplete-dropdown-${name}`"
        class="overflow-y-scroll"
        disablePadding
        :maxHeight="maxHeight"
        :width="width"
        shadow="lg"
      >
        <slot name="dropdown" v-bind="scope" />
      </Paper>
    </template>
  </Dropdown>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'

import Paper from '../Paper/Paper.vue'
import type { DropdownPosition } from '../Dropdown/types'

import Dropdown from '../Dropdown/Dropdown.vue'
import { positions } from '../Dropdown/classes'

import type * as InputWrapperType from '../Input/InputWrapper/types'
import * as inputWrapperClasses from '../Input/InputWrapper/classes'
import InputText from '../Input/InputText/InputText.vue'
import { InputWrapperVariant } from '../Input/InputWrapper/types'

defineProps({
  name: {
    type: String,
    required: true,
  },
  position: {
    type: String as PropType<DropdownPosition>,
    default: 'end',
    validator: (position: DropdownPosition) => !!positions[position],
  },
  variant: {
    type: String as PropType<InputWrapperVariant>,
    default: 'default',
    validator: (variant: InputWrapperVariant) => !!inputWrapperClasses.variant[variant],
  },
  size: {
    type: String as PropType<InputWrapperType.InputWrapperSize>,
    default: 'lg',
    validator: (size: InputWrapperType.InputWrapperSize) => !!inputWrapperClasses.size[size],
  },
  modelValue: {
    type: [String, Number],
    required: true,
  },
  hover: {
    type: Boolean,
    default: false,
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
  maxHeight: {
    type: String,
    default: null,
  },
  width: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
})

const emits = defineEmits(['update:modelValue', 'input'])
const handleInput = (e: any) => {
  emits('update:modelValue', e?.target.value)
  emits('input', e)
}
</script>
