import type { Router } from 'vue-router'

import { BreadcrumbsCrumb } from './types'

export const getBreadcrumbsData = (router: Router): BreadcrumbsCrumb[] => {
  const routeParams = router.currentRoute.value.params
  return router.currentRoute.value.matched
    .filter(route => !route.meta?.excludeBreadcrumb) // Exclude elements with related flag
    .map((route) => {
      // Check if the route path has params (including repeatable)
      if (/:\w+([+*])?/.test(route.path)) {
        // Extract all the param names from the route path
        const paramNames = route.path.match(/:(\w+)/g)?.map(name => name.slice(1))
        if (paramNames) {
          const paramSubstitutions = paramNames.map((paramName) => {
            const paramValue = routeParams[paramName]
            return Array.isArray(paramValue)
              ? paramValue.join('/') // Use '/' for joining repeatable params
              : paramValue
          })

          // Replace each parameter in the route path with its corresponding value
          let routePath = route.path
          paramNames.forEach((paramName, index) => {
            const paramRegex = new RegExp(`:${paramName}([+*])?`, 'g')
            routePath = routePath.replace(paramRegex, paramSubstitutions[index]!)
          })

          return {
            label: String(route.meta?.title || route.name || ''),
            path: routePath,
          }
        }
        else {
          return {
            label: String(route.meta?.title || route.name || ''),
            path: route.path,
          }
        }
      }
      else {
        return {
          label: String(route.meta?.title || route.name || ''),
          path: route.path,
        }
      }
    })
}

export const excludeElements = <T>(data: T[], excludeIndexes: number[]): T[] => {
  let dataIndexes = data.map((_, i) => i)

  dataIndexes = dataIndexes.filter(i => !excludeIndexes.includes(i))

  return dataIndexes.map(i => data[i]!)
}
