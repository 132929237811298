<template>
  <Grid container xs="7" gapY="0.5">
    <Grid
      v-for="(day, index) in allDays"
      :key="index"
      width="auto"
      item
      class="day-cell"
      :class="daysClasses(day)"
      :data-test-id="isSelected(day) ? `selected-${day.day.day}` : day.day.day"
    >
      <ButtonIcon
        class="day"
        :variant="buttonVariant(day)"
        :color="buttonColor(day)"
        size="lg"
        shape="circle"
        :disabled="isDisabled(day)"
        @click="handleDayClick(day)"
        @mouseover.self="handleDayHover(day)"
      >
        <Typography variant="body2" :color="typographyColor(day)">
          {{ day.day.toFormat(dateFormat) }}
        </Typography>
      </ButtonIcon>
    </Grid>
  </Grid>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon'
import { computed } from 'vue'
import { range } from 'lodash-es'

import ButtonIcon from '../../ButtonIcon/ButtonIcon.vue'
import Grid from '../../Grid/Grid.vue'
import Typography from '../../Typography/Typography.vue'
import { DisabledPeriod } from '../types'

import { DayType } from './DaysType'

const props = withDefaults(
  defineProps<{
    month: DateTime
    dateFormat?: string
    locale?: string
    firstDayOfTheWeek?: number
    min?: DateTime
    max?: DateTime
    disabled?: boolean
    disabledPeriods?: DisabledPeriod[]
    disabledWeekdays?: number[]
    disabledDays?: DateTime[]
    selectedDays?: DateTime[]
    periodDays?: DateTime[]
    periodPreview?: DateTime[]
    showPrevMonth?: boolean
    showNextMonth?: boolean
  }>(),
  {
    dateFormat: 'd',
    locale: 'en-US',
    firstDayOfTheWeek: 0,
    disabledPeriods: () => [],
    disabledWeekdays: () => [],
    disabledDays: () => [],
    selectedDays: () => [],
    periodDays: () => [],
    periodPreview: () => [],
    showPrevMonth: false,
    showNextMonth: false,
  },
)

const emits = defineEmits<{
  dayClick: [DateTime]
  dayHover: [DateTime]
}>()

const handleDayClick = (day: DayType) => {
  emits('dayClick', day.day)
}
const handleDayHover = (day: DayType) => {
  emits('dayHover', day.day)
}

const isDisabled = (day: DayType) => {
  if (props.disabled) {
    return true
  }
  if (props.disabledWeekdays.includes(day.day.weekday)) {
    return true
  }
  if (props.min && day.day < props.min.startOf('day')) {
    return true
  }
  if (props.max && day.day > props.max.endOf('day')) {
    return true
  }
  if (props.disabledDays.find(d => day.day.hasSame(d, 'day'))) {
    return true
  }
  if (
    props.disabledPeriods?.some(
      period => day.day >= period.start && day.day <= period.end,
    )
  ) {
    return true
  }

  return false
}

const isHiddenDay = (day: DayType) => {
  return (
    (day.before && !props.showPrevMonth) || (day.after && !props.showNextMonth)
  )
}

const isSinglePeriod = (period: DateTime[]) => {
  return period.length === 1
}

const isInPeriod = (day: DayType, period: DateTime[]) => {
  if (period.length < 2) {
    return false
  }
  const [first, last] = period
  return day.day > first!.endOf('day') && day.day < last!.startOf('day')
}

const isPeriodStart = (day: DayType, period: DateTime[], fullCheck = false) => {
  const legitimate = fullCheck ? period.length === 2 : !!period[0]
  return legitimate && day.day.hasSame(period[0]!, 'day')
}

const isPeriodEnd = (day: DayType, period: DateTime[], fullCheck = false) => {
  const legitimate = fullCheck ? period.length === 2 : !!period[1]
  return legitimate && day.day.hasSame(period[1]!, 'day')
}

const isToday = (day: DayType) => {
  return day.day.hasSame(DateTime.local(), 'day')
}

const isSelected = (day: DayType) => {
  return !!props.selectedDays.find(selectedDay =>
    day.day.hasSame(selectedDay, 'day'),
  )
}

const daysClasses = (day: DayType) => {
  return {
    'hidden-day': isHiddenDay(day),
    'today': isToday(day),
    'period': isInPeriod(day, props.periodDays),
    'selected': isSelected(day),
    'period-start': isPeriodStart(day, props.periodDays, true),
    'period-end': isPeriodEnd(day, props.periodDays, true),
    'period-preview': isInPeriod(day, props.periodPreview),
    'period-preview-single': isSinglePeriod(props.periodPreview),
    'period-preview-start': isPeriodStart(day, props.periodPreview),
    'period-preview-end': isPeriodEnd(day, props.periodPreview),
  }
}

const buttonVariant = (day: DayType) => {
  if (
    isSelected(day)
    || isPeriodStart(day, props.periodDays)
    || isPeriodEnd(day, props.periodDays)
    || isPeriodStart(day, props.periodPreview)
    || isPeriodEnd(day, props.periodPreview)
  ) {
    return 'default'
  }
  else if (isToday(day)) {
    return 'outlined'
  }
  return 'ghost'
}

const buttonColor = (day: DayType) => {
  if (
    isSelected(day)
    || isPeriodStart(day, props.periodDays)
    || isPeriodEnd(day, props.periodDays)
    || isPeriodStart(day, props.periodPreview)
    || isPeriodEnd(day, props.periodPreview)
    || isToday(day)
  ) {
    return 'primary'
  }
  return 'default'
}

const typographyColor = (day: DayType) => {
  if (
    isSelected(day)
    || isPeriodStart(day, props.periodDays)
    || isPeriodEnd(day, props.periodDays)
    || isPeriodStart(day, props.periodPreview)
    || isPeriodEnd(day, props.periodPreview)
  ) {
    return 'inherit'
  }
  else if (isDisabled(day)) {
    return 'disabled'
  }
  return 'textPrimary'
}

const previousMonthDays = computed(() => {
  const monthFirstDay = props.month.set({ day: 1 })
  const days = range(0, monthFirstDay.weekday)
    .map(index => monthFirstDay.minus({ days: index }))
    .reverse()

  const localeDays = days.slice(props.firstDayOfTheWeek)
  if (localeDays.length === 7) {
    return []
  }
  return localeDays.map(day => ({ day, before: true }))
})

const currentMonthDays = computed(() => {
  const days = range(0, props.month.daysInMonth!).map(index =>
    props.month.set({ day: index + 1 }),
  )
  return days.map(day => ({ day, current: true }))
})

const nextMonthDays = computed(() => {
  const amount = previousMonthDays.value.length + props.month.daysInMonth!
  const diff = 7 - (amount % 7)
  const render = range(0, diff).map(index =>
    props.month.set({ day: index + 1 }).plus({ months: 1 }),
  )
  return render.map(day => ({ day, after: true }))
})

const allDays = computed(() =>
  (
    [
      ...previousMonthDays.value,
      ...currentMonthDays.value,
      ...nextMonthDays.value,
    ] as DayType[]
  ).map(day => ({
    ...day,
    day: day.day.setLocale(props.locale),
  })),
)
</script>

<style scoped src="./days.styles.css" />
