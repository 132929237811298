<template>
  <Box flex wrap="wrap">
    <Box v-for="idx in panels" :key="idx" alignItems="center">
      <Panel
        v-bind="$attrs"
        :month="monthForIndex(idx - 1)"
        :selectedDays="selectedDays"
        :periodDays="periodDays"
        :periodPreview="periodPreview"
        :hideForward="idx < panels"
        :hideBack="idx > 1"
        :max="max"
        :min="min"
        :disabled="disabled"
        :disabledPeriods="disabledPeriods"
        :disabledWeekdays="disabledWeekdays"
        :noPadding="noPadding"
        :hideToday="hideToday"
        :locale="locale"
        :dateFormat="dateFormat"
        :firstDayOfTheWeek="currentFirstDayOfTheWeek"
        @monthChange="monthChange"
        @dayClick="dayClick"
        @dayHover="dayHover"
        @setMonth="setMonth"
        @setDate="setDate"
        @setYear="setYear"
      >
        <template #bottom>
          <slot name="bottom" />
        </template>
      </Panel>
    </Box>
  </Box>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { DateTime, Info } from 'luxon'

import Box from '../Box/Box.vue'
import Panel from '../DatePicker/Panel/Panel.vue'
import { isInRange, isLowerBound, isUpperBound } from '../InputDate/utils'

import { DateFormatTypes, DisabledPeriod, PeriodDate } from './types'

type DatePickerMode = 'single' | 'multiple' | 'period'

const props = withDefaults(defineProps<{
  panels?: number
  locale?: string
  dateFormat: DateFormatTypes
  firstDayOfTheWeek?: number
  mode?: DatePickerMode
  noPadding?: boolean
  hideToday?: boolean
  disabled?: boolean
  disabledWeekdays?: number[]
  initialMonth?: DateTime | null
  min?: DateTime
  max?: DateTime
  disabledPeriods?: DisabledPeriod[]
  // update start or end date in period mode
  periodChooseDate?: PeriodDate
  period?: DateTime[]
  selected?: DateTime[]
  selectedSingle?: DateTime | null
}>(), {
  panels: 1,
  locale: 'en-US',
  dateFormat: 'MM/dd/yyyy',
  mode: 'single',
  noPadding: false,
  hideToday: false,
  disabledWeekdays: () => [],
  disabledPeriods: () => [],
  period: () => [],
  selected: () => [],
})

const emits = defineEmits<{
  selectedUpdate: [DateTime[]]
  selectedChoose: [DateTime]
  periodChoose: [DateTime[]]
}>()

defineOptions({
  inheritAttrs: false,
})

const month = ref(DateTime.local())
const selectedDays = ref<DateTime[]>([])
// TODO: redefine all period-related stuff as [DateTime, DateTime]
const periodDays = ref<DateTime[]>([])
// preview of the period on any day hover while second day not yet clicked
const periodPreview = ref<DateTime[]>([])

const currentFirstDayOfTheWeek = computed(() => {
  if (props.firstDayOfTheWeek !== null) {
    return props.firstDayOfTheWeek
  }

  const defaultFirstDay = Info.weekdays('short', { locale: props.locale })[0]!
  const localWeekdays = Info.weekdays('short', { locale: props.locale })

  return localWeekdays.indexOf(defaultFirstDay)
})

const shortDateFormat = computed(() => ['MM/yy', 'MM/yyyy'].includes(props.dateFormat))

const dayClick = (day: DateTime) => {
  let newDay = day.setLocale(props.locale)
  if (shortDateFormat.value) {
    newDay = newDay.startOf('month')

    if (props.min && isLowerBound(day, props.min, props.dateFormat)) {
      newDay = props.min.setLocale(props.locale)
    }
    if (props.max && isUpperBound(day, props.max, props.dateFormat)) {
      newDay = props.max.setLocale(props.locale)
    }
  }

  if (!isInRange(newDay, props.min, props.max)) {
    return
  }

  if (props.mode === 'single') {
    emits('selectedChoose', newDay)
  }
  else if (props.mode === 'multiple') {
    const exists = selectedDays.value.find(d => d.hasSame(newDay, 'day'))
    if (exists) {
      selectedDays.value = selectedDays.value.filter(d => !d.hasSame(newDay, 'day'))
    }
    else {
      selectedDays.value.push(newDay)
    }
    emits('selectedUpdate', selectedDays.value)
  }
  else if (props.mode === 'period') {
    let days = [newDay]

    if (periodDays.value.length > 1 && props.periodChooseDate) {
      const [startDate, endDate] = periodDays.value

      days = props.periodChooseDate === 'start' ? [newDay, endDate!] : [startDate!, newDay]
      periodDays.value = days
      periodPreview.value = []
    }
    else {
      periodPreview.value = [newDay]

      if (periodDays.value.length === 1 && !newDay.hasSame(periodDays.value[0]!, 'day')) {
        days = [periodDays.value[0]!, newDay].sort((a, b) => a.toMillis() - b.toMillis())
        periodPreview.value = []
      }
      periodDays.value = days
    }

    if (shortDateFormat.value) {
      let [startDate, endDate] = periodDays.value
      if (startDate && endDate && props.max && !isUpperBound(endDate, props.max, props.dateFormat)) {
        endDate = endDate.endOf('month')
        periodDays.value = [startDate, endDate]
      }
    }

    emits('periodChoose', periodDays.value)
  }
}

const monthChange = (diff: number) => {
  month.value = month.value.plus({ months: diff })
  if (shortDateFormat.value) {
    dayClick(month.value)
  }
}

const setMonth = (newMonth: DateTime) => {
  month.value = newMonth
  if (shortDateFormat.value) {
    dayClick(month.value)
  }
}

const setDate = (day: DateTime) => {
  dayClick(day)
}

const setYear = (year: DateTime) => {
  month.value = year
  if (shortDateFormat.value) {
    dayClick(month.value)
  }
}

const dayHover = (day: DateTime) => {
  if (props.mode === 'period' && periodDays.value.length === 1) {
    if (day.hasSame(periodDays.value[0]!, 'day')) {
      periodPreview.value = []
    }
    else {
      periodPreview.value = [periodDays.value[0]!, day].sort((a, b) => a.toMillis() - b.toMillis())
    }
  }
}

const monthForIndex = (idx: number) => {
  if (props.mode === 'period' && shortDateFormat.value) {
    const [startDate, endDate] = periodDays.value
    if (startDate && props.periodChooseDate === 'start') {
      month.value = startDate
    }
    else if (endDate && props.periodChooseDate === 'end') {
      month.value = endDate
    } // Sets the selected month for the periodChooseDate
  }
  if (!DateTime.isDateTime(month.value)) {
    month.value = DateTime.now().setLocale(props.locale)
  }
  if (idx === 0) {
    return month.value.setLocale(props.locale)
  }
  return month.value.plus({ months: idx }).setLocale(props.locale)
}

watch(
  () => props.period,
  () => {
    if (props.mode === 'period') {
      periodDays.value = props.period
    }
  },
  { immediate: true },
)

watch(
  () => props.selected,
  () => {
    if (props.selected.length) {
      selectedDays.value = props.selected
    }
  },
  { immediate: true },
)

watch(
  () => props.selectedSingle,
  () => {
    if (DateTime.isDateTime(props.selectedSingle)) {
      selectedDays.value = [props.selectedSingle]
      month.value = props.selectedSingle
    }
  },
  { immediate: true },
)

watch(() => props.initialMonth,
  () => {
    if (props.initialMonth) {
      month.value = props.initialMonth
    }
  }, {
    immediate: true,
  })

if (props.selectedSingle) {
  month.value = props.selectedSingle
}
else if (props.period?.[0]) {
  month.value = props.period[0]
}
</script>
