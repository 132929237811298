<template>
  <Box
    flex
    direction="row"
    class="selected-item"
    alignItems="center"
    spaceX="2"
    minWidth="10px"
  >
    <Box flex alignItems="center" spaceX="2" minWidth="10px" grow="grow">
      <slot name="content">
        <slot name="chip">
          <template v-if="chips.length > 0">
            <Box v-for="_chip in chips" :key="_chip" minWidth="10px" flex>
              <Chip variant="outlined" size="2xs" :label="_chip" />
            </Box>
          </template>
        </slot>
        <Typography variant="body2" class="basis-0 grow" wordBreak="anywhere">
          <slot name="label">
            {{ label }}&nbsp;<Tooltip
              v-if="info"
              :content="info"
              placement="top"
              withIcon
              inline
              class="align-middle"
            />
          </slot>
        </Typography>
      </slot>
      <Box v-if="enabled !== undefined" flex alignItems="center" ml="auto">
        <Toggle
          v-model="enabledInternal"
          :value="value as any"
          color="success"
          size="xs"
          :offColor="offColor"
          :disabled="disabled"
          :readonly="noToggle"
        />
      </Box>
    </Box>

    <ButtonIcon :disabled="disabled" icon="close" ml="2" @click="onRemove()" />
  </Box>
</template>

<script setup lang="ts" generic="T extends boolean | any[]">
import { computed } from 'vue'

import { useVModel } from '@vueuse/core'

import { arrayify } from '@lasso/shared/utils'

import Box from '../Box/Box.vue'
import ButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import Chip from '../Chip/Chip.vue'
import Toggle from '../Toggle/Toggle.vue'
import Tooltip from '../Tooltip/Tooltip.vue'
import Typography from '../Typography/Typography.vue'
import { ToggleColor } from '../Toggle/types'

const props = withDefaults(
  defineProps<{
    enabled?: T
    value?: T extends any[] ? T[number] : never
    noToggle?: boolean
    chip?: string | number | Array<string | number>
    info?: string
    label?: string
    disabled?: boolean
    offColor?: ToggleColor
  }>(),
  {
    noToggle: false,
    chip: '',
    info: '',
    label: '',
    disabled: false,
    offColor: 'error',
    enabled: undefined,
    value: undefined,
  },
)

const emit = defineEmits<{
  'update:enabled': [T]
  remove: []
}>()

const enabledInternal = useVModel(props, 'enabled', emit)
const chips = computed(() =>
  arrayify(props.chip).filter(chip => chip !== ''),
)

const onRemove = () => {
  emit('remove')
}
</script>

<style scoped>
.selected-item {
  @apply bg-base-200 rounded-xl px-4 py-2 truncate;
}
</style>
