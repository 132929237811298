<template>
  <Box class="w-full" flex col spaceY="1">
    <Autocomplete2
      v-model="modelValueInternal"
      :minLength="minLength"
      :search="search"
      :debounceDelay="debounceDelay"
      :placeholder="placeholder"
      :options="options"
      :icon="icon"
      :error="error"
      :loading="loading"
      :withValue="withValue"
      variant="default"
      :multiple="true"
      hideSelectedValues
    />
    <Box v-if="selectedOptions.length > 0" flex col spaceY="1">
      <SelectedOption
        v-for="option in selectedOptions"
        :key="option.value"
        :option="option"
        :withValue="withValue"
        @deselect="deselectOption(option)"
      />
    </Box>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useVModel } from '@vueuse/core'

import { objFromEntries, truthy } from '@lasso/shared/utils'

import Box from '../Box/Box.vue'
import Autocomplete2 from '../Autocomplete2/Autocomplete.vue'

import type { SelectOptionType, SelectOptionValueType } from '../Select/types'

import SelectedOption from './SelectedOption.vue'

const props = withDefaults(defineProps<{
  modelValue: SelectOptionValueType[]
  minLength?: number
  search?: (val: string) => Promise<SelectOptionType[]>
  debounceDelay?: number
  placeholder?: string
  options: SelectOptionType[]
  icon?: string
  error?: boolean
  loading?: boolean
  withValue?: boolean
}>(), {
  minLength: 1,
  debounceDelay: 500,
  placeholder: 'Search',
  options: () => [],
  icon: 'search',
  withValue: false,
})

const emit = defineEmits<{
  'update:modelValue': [SelectOptionValueType[]]
}>()

const modelValueInternal = useVModel(props, 'modelValue', emit)

const optionsMap = computed(() => objFromEntries(props.options.map(option => [option.value, option] as const)))

const selectedOptions = computed(() => {
  return modelValueInternal.value
    .map(value => optionsMap.value[value])
    .filter(truthy)
})

const deselectOption = (option: SelectOptionType) => {
  modelValueInternal.value = modelValueInternal.value.filter(value => value !== option.value)
}
</script>
