<template>
  <input
    :id="id"
    v-model="checked"
    :disabled="disabled"
    type="radio"
    :value="value"
    :name="name"
    class="radio border-2"
    :class="radioClasses"
  >
</template>

<script lang="ts" setup generic="T">
import { Ref, computed, inject, ref } from 'vue'

import { useDaisyUiAnimationCrutch } from '../../hooks'
import { checkForUndefined } from '../../utils'

import { colors, sizes } from './classes'
import { RadioProps } from './types'

const props = withDefaults(defineProps<RadioProps<T>>(), {
  disabled: false,
  color: 'secondary',
  size: 'sm',
})

const emits = defineEmits<{
  'update:modelValue': [T]
}>()

const validationHandleChange = inject<Ref<((value: T) => void) | null>>('validationHandleChange', ref(null))

const checked = computed({
  get: () => props.modelValue,
  set: (value) => {
    emits('update:modelValue', value)

    if (typeof validationHandleChange.value === 'function') {
      validationHandleChange.value(value)
    }
  },
})

const { isAnimationDisabled } = useDaisyUiAnimationCrutch(() => props.modelValue)

const radioClasses = computed(() => {
  const color = checkForUndefined(props.color, colors)
  const size = checkForUndefined(props.size, sizes)

  return {
    [color]: !!props.color,
    [size]: !!props.size,
    'no-animation': isAnimationDisabled.value,
  }
})
</script>

<style scoped>
.radio:checked {
  box-shadow: 0 0 0 3px currentcolor inset, 0 0 0 3px currentcolor inset;
}

.radio:not(:checked) {
  @apply border-divider;
}
</style>
