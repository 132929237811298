<template>
  <InputWrapper
    :disabled="disabled"
    :withValue="Boolean(modelValue)"
    :focused="focused"
    :error="errorInternal"
  >
    <textarea
      :id="id"
      v-model="modelValueInternal"
      :name="name"
      :cols="cols"
      :rows="rows"
      :disabled="disabled"
      :class="classes"
      :placeholder="placeholder"
      @focus="onFocus"
      @blur="onBlur"
    />
  </InputWrapper>
</template>

<script setup lang="ts">
import { Ref, computed, inject, ref } from 'vue'

import InputWrapper from '../Input/InputWrapper/InputWrapper.vue'

const props = withDefaults(defineProps<{
  modelValue: string
  id?: string
  name?: string
  cols?: string | number
  rows?: string | number
  disabled?: boolean
  error?: boolean
  resize?: 'x' | 'y' | boolean
  placeholder?: string
}>(), {
  resize: false,
})

const emit = defineEmits<{
  'update:modelValue': [string]
  focus: [FocusEvent]
  blur: [FocusEvent]
}>()
const formControlHasError = inject('formControlHasError', ref(false))
const validationHandleChange = inject<Ref<((value: string) => void) | null>>('validationHandleChange', ref(null))
const errorInternal = computed(() => formControlHasError.value || props.error || false)

const modelValueInternal = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)

    if (typeof validationHandleChange.value === 'function') {
      validationHandleChange.value(value)
    }
  },
})

const focused = ref(false)
const resizeClasses = new Map<boolean | string, string>([
  [true, 'resize'],
  [false, 'resize-none'],
  ['x', 'resize-x'],
  ['y', 'resize-y'],
])

const classes = computed(() => [
  'textarea',
  resizeClasses.get(props.resize),
  {
    'w-full': props.cols === undefined,
  },
])

const onFocus = (event: FocusEvent) => {
  focused.value = true
  emit('focus', event)
}

const onBlur = (event: FocusEvent) => {
  focused.value = false
  emit('blur', event)
}
</script>
