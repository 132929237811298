<template>
  <div class="lui-checkbox-wrapper">
    <input
      :id="id"
      v-model="checked"
      type="checkbox"
      class="lui-checkbox"
      :class="checkboxClasses"
      :disabled="disabled"
      :name="name"
      :value="value"
    >

    <svg
      v-if="active && !indeterminate"
      class="lui-checkbox-checkmark"
      xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9" fill="none"
    >
      <path d="M1 4.4126L3.66667 7.4126L9 1.4126" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg
      v-if="indeterminate"
      class="lui-checkbox-indeterminate-mark"
      xmlns="http://www.w3.org/2000/svg" width="8" height="3" viewBox="0 0 8 3" fill="none"
    >
      <path d="M7.42857 2.87822H0.571429C0.257143 2.87822 0 2.40737 0 1.83187C0 1.25638 0.257143 0.785522 0.571429 0.785522H7.42857C7.74286 0.785522 8 1.25638 8 1.83187C8 2.40737 7.74286 2.87822 7.42857 2.87822Z" />
    </svg>
  </div>
</template>

<script lang="ts" setup generic="T extends boolean | any[]">
import { Ref, computed, inject, ref } from 'vue'
import { looseIndexOf } from '@vue/shared'

import { checkForUndefined } from '../../utils'

import { colors, sizes } from './classes'
import {
  CheckboxColor,
  CheckboxSize,
} from './types'

const props = withDefaults(defineProps<{
  id?: string
  modelValue: T
  value?: T extends any[] ? T[number] : never
  indeterminate?: boolean
  name?: string
  disabled?: boolean
  color?: CheckboxColor
  size?: CheckboxSize
}>(), {
  indeterminate: false,
  disabled: false,
  id: undefined,
  value: undefined,
  name: undefined,
  color: 'secondary',
  size: 'md',
})

const emit = defineEmits<{
  'update:modelValue': [T]
}>()

const validationHandleChange = inject<Ref<((value: T) => void) | null>>('validationHandleChange', ref(null))

const checked = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)

    if (typeof validationHandleChange.value === 'function') {
      validationHandleChange.value(value)
    }
  },
})

const active = computed(() => {
  if (Array.isArray(props.modelValue)) {
    return looseIndexOf(props.modelValue, props.value) !== -1
  }
  else {
    return props.modelValue
  }
})

const checkboxClasses = computed(() => {
  const size = checkForUndefined(props.size, sizes)
  const color = checkForUndefined(props.color, colors)

  return {
    [size]: !!props.size,
    [color]: !!props.color,
    'lui-checkbox-active': active.value,
    'lui-checkbox-indeterminate': props.indeterminate,
  }
})
</script>

<style scoped src="./checkbox.styles.css"></style>
