<template>
  <Box class="alert border rounded-xl" px="4" py="2" :class="alertClasses">
    <Box flex direction="row" grow="grow" spaceX="4">
      <Box v-if="icon" flex alignSelf="center" justify="center">
        <Icon :icon="icon" color="inherit" :variant="iconVariant" />
      </Box>

      <Box flex direction="col" grow="grow" spaceY="1">
        <Typography v-if="title" variant="subtitle2" color="textPrimary" wordBreak="words">
          {{ title }}
        </Typography>

        <Typography variant="body2" color="textSecondary" wordBreak="words">
          <slot>
            {{ text }}
          </slot>
        </Typography>
      </Box>
      <ButtonIcon v-if="cancelable" icon="close" @click="handleCancel" />
    </Box>
  </Box>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import Box from '../Box/Box.vue'
import Icon from '../Icon/Icon.vue'
import ButtonIcon from '../ButtonIcon/ButtonIcon.vue'
import Typography from '../Typography/Typography.vue'

import { checkForUndefined } from '../../utils'

import type { AlertPropsType } from './AlertType'
import { colors, icons } from './classes'

const props = withDefaults(defineProps<AlertPropsType>(), {
  title: '',
  text: '',
  color: 'default',
  cancelable: false,
})

const emits = defineEmits<{
  cancel: []
}>()

const icon = computed(() => props.icon ? props.icon : checkForUndefined(props.color, icons) as string)

const alertClasses = computed(() => {
  const color = checkForUndefined(props.color, colors)

  return {
    [color]: !!props.color,
  }
})

const handleCancel = () => {
  emits('cancel')
}
</script>

<style scoped src="./alert.styles.css" />
